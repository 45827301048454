import { StaticImage } from "gatsby-plugin-image"
import React, { useCallback, useEffect } from "react"
import { FormattedMessage, IntlProvider } from "react-intl"
import { handleSubmitForm } from "../utils/form"

import "../styles/register.scss"

export default function Register({
  location,
  pageContext: { messages, language },
}) {
  const goBack = useCallback(() => {
    typeof window !== "undefined" && window.history.back()
  }, [])

  const event = location?.state?.event

  useEffect(() => {
    !event && goBack()
  }, [event])

  return (
    <IntlProvider locale={language} messages={messages}>
      <div className={`register-page ${language}`}>
        <span
          tabIndex={-1}
          role="button"
          onKeyPress={keyEvent => keyEvent.key === "Enter" && goBack}
          onClick={goBack}
          className={`close`}
        >
          <StaticImage className="close" alt="x" src="../assets/nav/x.png" />
        </span>
        <h1>
          <FormattedMessage id="register_title" />
        </h1>
        <h2>{event?.title}</h2>
        <h2 className="date">
          {new Date(event?.time).toLocaleDateString("en-GB")}
        </h2>
        <form
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          name="register-page"
          id="register-page"
          onSubmit={e =>
            handleSubmitForm(e, () => {
              alert(messages["form_success"])
              goBack()
            })
          }
        >
          <input type="hidden" name="form-name" value="register-page" />
          <input type="hidden" name="event" value={event?.title} />
          <input type="hidden" name="date" value={event?.time} />
          <div className="stay-updated">
            <input type={"checkbox"} name="stay-updated" />{" "}
            <p>
              <FormattedMessage id="register_stay-updated" />
            </p>
          </div>
          <div className="form-content">
            <input
              placeholder={messages["global_name"]}
              name="name"
              required
              id="name"
            />
            <input
              placeholder={messages["global_email"]}
              name="email"
              id="email"
              required
              pattern="(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}"
              title="email not valid"
            />
            <input placeholder={messages["global_phone"]} name="phone" />
            <button type="submit">
              <FormattedMessage id="contact_form-send" />
            </button>
          </div>
        </form>
      </div>
    </IntlProvider>
  )
}

export { Head } from "../components/Head"
